import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavBar.scss';

const NavBar = props => (
	<div className="topbar border-b">
		<div className="flex justify-center">
			<div className="w-full">
				<div className="border rounded-[30px] py-[5px] px-[10px] bg-[#F5F6FA] w-5/12">
					<input type="text" className="search  form-control bg-transparent outline-none" placeholder="Search" />
				</div>
			</div>
			<div className="nav_bar_left">
				<div className="flex items-center gap-3">
					<span>
						<button type="button" className="relative inline-flex items-center p-1 text-sm font-medium text-center text-white rounded-lg hover:drop-shadow-xl hover:ring-2 hover:outline-none hover:ring-blue-500">
							<img src="/images/svg/noticationBell.svg" alt="" className='w-7' />
							<span className="sr-only">Notifications</span>
							<div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 left-4 end-0 dark:border-gray-900">20</div>
						</button>
					</span>
					<span className='cursor-pointer ml-4'>
						<button type="button" className="relative inline-flex items-center  text-sm font-medium text-center text-white rounded-lg hover:drop-shadow-xl focus:ring-2 focus:outline-none focus:ring-blue-500 p-3">
							<div className="flex items-center justify-end gap-3 px-3">
								<img src="/images/svg/flag.svg" alt="" />
								<p className='text-sm m-0 text-black'>English</p>
							</div>
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
);

// todo: Unless you need to use lifecycle methods or local state,
// write your component in functional form as above and delete
// this section. 
// class NavBar extends React.Component {
//   render() {
//     return <div>This is a component called NavBar.</div>;
//   }
// }

const NavBarPropTypes = {
	// always use prop types!
};

NavBar.propTypes = NavBarPropTypes;

export default NavBar;
