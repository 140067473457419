import React, { useState } from 'react'

import Table from '../../Table'
import { chcekValueAndReturn } from '../../../utils/linkCheck';

function EmailListTable() {

  const columns = React.useMemo(
    () => [
      {
        "Header": "Guppy ID",
        "accessor": "id"
      },
      {
        "Header": "Lead Name",
        "accessor": "lead_name",
        Cell: ({ value }) => chcekValueAndReturn(value),
      },
      {
        "Header": "Business Info",
        "accessor": "buss_info"
      },
      {
        "Header": "Industry",
        "accessor": "industry"
      },
      {
        "Header": "Data in Source",
        "accessor": "data_source"
      },
      {
        "Header": "Recommended Product",
        "accessor": "recc_product",
        Cell: ({ value }) => chcekValueAndReturn(value),
      }
    ],
    []
  );

  const data = React.useMemo(
    () => [
      {
        id: '#74303',
        recc_product: '/images/svg/denefits.svg',
        lead_name: 'Jane Cooper',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#24355',
        recc_product: '/images/svg/practina.svg',
        lead_name: 'Jerome Bell',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#23422',
        recc_product: '/images/svg/cc.svg',
        lead_name: 'Jacob Jones',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#32324',
        recc_product: '/images/svg/practina.svg',
        lead_name: 'Annette Black',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#32445',
        recc_product: '/images/svg/denefits.svg',
        lead_name: 'Albert Flores',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#64534',
        recc_product: '/images/svg/cc.svg',
        lead_name: 'Wade Warren',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#23535',
        recc_product: '/images/svg/practina.svg',
        lead_name: 'Kristin Watson',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#52256',
        recc_product: '/images/svg/cc.svg',
        lead_name: 'Esther Howard',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#24252',
        recc_product: '/images/svg/denefits.svg',
        lead_name: 'Bessie Cooper',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#24566',
        recc_product: '/images/svg/practina.svg',
        lead_name: 'Ralph Edwards',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#55666',
        recc_product: '/images/svg/cc.svg',
        lead_name: 'Guy Hawkins',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#24355',
        recc_product: '/images/svg/denefits.svg',
        lead_name: 'Dent-Mag',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      },
      {
        id: '#24355',
        recc_product: '/images/svg/practina.svg',
        lead_name: 'Dent-Mag',
        industry: 'Source Name',
        data_source: 'Google Ads',
        buss_info: <div>
          <div>
            <p>Dental Plus</p>
            <p>vuhaithuongnute@gmail.com</p>
            <p>(217) 555-0113</p>
          </div>
        </div>
      }
    ],
    []
  );



  return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  )
}

export default EmailListTable