export const setStorageKey = (key, value) => {
  const data = localStorage.getItem('user_storage');
  const storage = data ? JSON.parse(data) : {};
  storage[key] = value;
  localStorage.setItem('user_storage', JSON.stringify(storage));
};

export const clearPartnerStorage = () => {
  localStorage.removeItem('user_storage');
};

export const getStorageValue = (key) => {
  const data = localStorage.getItem('user_storage');
  const storage = data ? JSON.parse(data) : {};
  const keys = key.split('.');
  let result = storage;
  for (let k of keys) {
    if (result && result.hasOwnProperty(k)) {
      result = result[k];
    } else {
      return undefined;
    }
  }
  return result;
};;