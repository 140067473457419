const checkValidLinkandFix = (link) => {
    const regx = /https?:\/\//i;
    if (regx.test(link)) {
        return link
    }
    else {
        return ('https://' + link)
    }
}

const chcekValueAndReturn = (inputString) => {
    console.log(inputString)
    // Regular expression patterns for URL and image file extensions
    const urlPattern = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+[/#?]?.*)$/i;
    const imageExtensions = /\.(jpeg|jpg|gif|png|svg|webp)$/i;

    // Check if the input string matches URL pattern
    if (urlPattern.test(inputString)) {
        return <a href={checkValidLinkandFix(inputString)} target='_blank'>Click to view</a>; // String matches URL pattern
    }

    // Check if the input string ends with an image extension
    if (imageExtensions.test(inputString)) {
        return <img src={inputString} />; // String ends with image extension
    }

    // If neither URL nor image path, consider it a normal string
    return inputString;
}


export {checkValidLinkandFix,chcekValueAndReturn}