import React from 'react'
import "./Leads.scss"

const Leads = () => {
    return (
        <div className='leads'>
            <div className='row m-0'>
                <div className='col-12 p-0'>
                    <div className="leads-header">
                        <h1>Leads</h1>
                        <div>
                            <button className='btn btn-primary'>Add New Leads</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leads