import './App.scss';
const  App = () => {
  return (
    <></>
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Login />} />
    //     <Route path="/new-password" element={<NewPassword />} />
    //     <Route path="*" element={<NoPage />} />
    //   </Routes>
    // </BrowserRouter>
  );
}




export default App;
