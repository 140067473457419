import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../sidebar/Sidebar'
import "./Layout.scss"
import NavBar from '../../components/NavBar/NavBar'

const Layout = () => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-row">
                <div className="w-1/4 xl:w-1/6 p-0">
                    <Sidebar />
                </div>
                <div className="w-3/4 xl:w-5/6 p-0">
                    <NavBar />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout