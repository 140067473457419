import React from 'react';
import { Link } from 'react-router-dom';
import {getStorageValue } from '../../services/sharedServices';
// import { logo } from '../../../public/assets/Assets';
import './SideBar.scss';
import clsx from 'clsx';
import { persistor } from '../../Redux/persist';
import { useDispatch, useSelector } from 'react-redux';
const Sidebar = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.APIData.profileData);
  const handleLogout = () =>{
    persistor.purge().then(() => {
      console.log('Persisted state has been purged');
    });
    dispatch({ type: 'RESET_STATE' });

  }

  return (
    <div className="sidebar">
      <div className="logo"><img src='/assets/logo.svg' alt="" /></div>
      <div className='main-wrap'>
        <div className='top w-100'>
          <nav>
            <ul>
              {profileData.menu.map((item, index) => (
                <li key={index} className={clsx({ 'dropdown': item.children })}>
                  <Link to={item.path}>{item.name}</Link>
                  {item.children && (
                    <ul>
                      {item.children.map((child, childIndex) => (
                        <li key={childIndex}>
                          <Link to={child.path}>{child.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="settings">
            <ul>
              <li><Link to="/setting">Settings</Link></li>
            </ul>
          </div>
        </div>


        <div className='footer'>
          <div className="profile">
            <div className='me-4'>
              <img src="assets/profileDummy.svg" alt="Jessica James" />
            </div>
            <div>
              <p>{profileData.name}</p>
              <p className="role">Super Admin</p>
            </div>
          </div>
          <div className="help-logout">
            <Link to="/help">Help</Link>
            <Link to="/" onClick={handleLogout}>Logout Account</Link>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Sidebar;
