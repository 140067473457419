import React from 'react'
import { Outlet } from 'react-router-dom'

import './Layout.scss';

function Layout() {
  return (
    <div className='row gx-0 px-4 bg-[#F5F5F5]'>
        <Outlet />
    </div>
  )
}

const MarketingLayout = Layout

export default MarketingLayout
