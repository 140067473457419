import React, { useState, useEffect } from 'react';
import { post } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { getStorageValue } from '../services/sharedServices';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const NewPassword =() => {
    // const profileData = getStorageValue('user_info_data');
    const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const profileData = useSelector((state) => state.APIData.profileData);
    useEffect(() => {
        if (!(profileData && profileData.reset_password === 1 && profileData.token)) {
            navigate('/');
        }
    }, [profileData, navigate]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let newErrors = {};
            if (!formData.password.trim()) {
                newErrors.password = 'Enter your password';
            }
            if (!formData.confirmPassword.trim()) {
                newErrors.confirmPassword = 'Please confirm your password';
            }
            if (formData.password.trim() !== formData.confirmPassword.trim()) {
                newErrors.matchPassword = "Password don't match";
            }
            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
            } else {
                newErrors = {};
                setErrors(newErrors);
                let token = profileData.token;
                formData.user_id = profileData.id;
                const result = await post('/update_password', formData, token);
                if (result && result.is_error === 1) {
                    newErrors.backendError = result.err;
                    setErrors(newErrors);
                } else {
                    newErrors = {};
                    setErrors(newErrors);
                    toast.success('Login Success')

                }
            }

        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };
    return (
        <div className="min-h-[calc(100vh-78px)] bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-6">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-10 w-auto" src="https://www.svgrepo.com/show/301692/login.svg" alt="Workflow" />
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Change Password
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form onSubmit={handleSubmit}>
                        <div className="mt-6">
                            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">Password</label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <input id="password" name="password" type="password" required="" value={formData.password}
                                    onChange={handleChange} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" minLength={5} maxLength={10}/>
                                {errors.password && <span className="error-message-border">{errors.password}</span>}
                            </div>
                        </div>
                        <div className="mt-6">
                            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">Confirm Password</label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <input id="password" name="confirmPassword" type="password" required="" value={formData.confirmPassword}
                                    onChange={handleChange} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" minLength={5} maxLength={10}/>
                                {errors.confirmPassword && <span className="error-message-border">{errors.confirmPassword}</span>}
                            </div>
                        </div>
                        {errors.matchPassword && <span className="error-message-border">{errors.matchPassword}</span>}
                        <div className="mt-6">
                            <span className="block w-full rounded-md shadow-sm">
                                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    Change
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default NewPassword;