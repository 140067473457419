import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';

const Table = ({ columns, data: initialData }) => {
	const [data, setData] = useState(initialData);

	const updateMyData = (rowIndex, columnId, value) => {
		setData((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};

	// const defaultColumn = {
	// 	Cell: EditableCell,
	// };

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
			// defaultColumn,
			updateMyData,
		},
		useSortBy
	);

	return (
		<table {...getTableProps()} className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
			<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
				{headerGroups.map((headerGroup, headerGroupIndex) => (
					<tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()} scope="col" className="px-6 py-3 h-16">
						{headerGroup.headers.map((column, columnIndex) => (
							<th
								{...column.getHeaderProps(column.getSortByToggleProps())}
								className="px-4 py-2 text-left text-sm font-semibold" key={columnIndex}
							>
								{column.render('Header')}
								<span>
									{column.isSorted ? (column.isSortedDesc ? <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
										<path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
									</svg> : ' 🔼') : ''}
								</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, index1) => {
					// row?.cells[1]?.value = chcekValueAndReturn(row.cells[1].value)
					prepareRow(row);
					return (
						<tr key={index1} {...row.getRowProps()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 h-14">
							{row.cells.map((cell, cellKey) => (
								<td key={cellKey} {...cell.getCellProps()} className="px-4 py-2 text-sm">
									{cell.render('Cell')}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Table;


// components/EditableCell.js

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we will define later
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed, update the state of the cell
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} className="border p-1" />;
};

