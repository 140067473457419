import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getStorageValue } from '../services/sharedServices';

const PrivateRoutes = () => {
  const token = getStorageValue('user_info_data.token');
  const isAuthenticated = !!token;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
