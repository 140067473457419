import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";
import NewPassword from "./pages/NewPassword";
import Nopage from "./pages/NoPage";
import Login from "./pages/Login";
import PrivateRoutes from "./auth/PrivateRoutes";
import Dashboard from "./components/dashboard/Dashboard";
import Layout from "./core/layout/Layout";
import Leads from "./components/leads/Leads";
import MarketingLayout from "./components/Marketing/Layout";
import EmailListTable from "./components/Marketing/EmailTemplate/EmailListTable";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} />
      <Route element={<Layout />} >
        <Route path="/" element={<PrivateRoutes />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="leads/dashboard" element={<Leads />} />
          <Route path="marketing" element={<MarketingLayout />}>
            <Route path="email-templates" element={<EmailListTable />} />
          </Route>
          <Route path="new-password" element={<NewPassword />} />
        </Route>
      </Route>
      <Route path="*" element={<Nopage />} />
    </>
  )
);