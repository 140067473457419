import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    profileData: {},
    dashboardData:{}
};

export const MainSlice = createSlice({
    name: 'APIData',
    initialState,
    reducers: {
        setProfileData(state, action) {
            state.profileData = action.payload;
        },
        setDashboardData(state, action) {
            state.dashboardData = action.payload;
        }
    }
});

// Export the actions generated by createSlice
export const { setProfileData,setDashboardData } = MainSlice.actions;

// Export the reducers to be used in the store configuration
export default MainSlice.reducer;



















// import { SET_PROFILE_DATA, SET_DASHBOARD_DATA } from './Actions';

// const initialState = {
//     profileData: {},
//     dashboardData: {}
// };

// export const profileReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case SET_PROFILE_DATA:
//             return {
//                 ...state,
//                 profileData: action.payload,
//             };
//         default:
//             return state;
//     }
// };

// export const dashboardReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case SET_DASHBOARD_DATA:
//             return {
//                 ...state,
//                 dashboardData: action.payload,
//             };
//         default:
//             return state;
//     }
// };