const BASE_URL = process.env.REACT_APP_API_URL;
export const get = async (endpoint, queryParams, token) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw new Error('An error occurred while fetching data');
  }
};

export const post = async (endpoint, data, token) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw new Error('An error occurred while posting data');
  }
};