import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE } from 'redux-persist'
import RootReducer from '../RootReducer'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit';
import { REGISTER } from 'redux-persist/es/constants';

const persistConfig = {
    key: 'root',
    storage,
  };
  
  const persistedReducer = persistReducer(persistConfig, RootReducer);
  
  // Configure store
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
  
  const persistor = persistStore(store);

  export { store, persistor };
